import {
  InventureUser,
  BankAccount,
  LegalPerson,
  BenefitingPerson as BenefitingPerson,
  Address,
  BankAndTax,
  TaxInformation,
} from "../types/inventure-user_types";
import { Campaign, Fund } from "../types/fund_types";
import {
  OnboardingData,
  OnboardingZero,
  SignupData,
} from "../types/onboarding_types";
import {
  Investment,
  Survey,
  CreateInvestmentDTO,
  SignedDocument,
  WAIVER_TYPE,
  Voucher,
} from "../types/investment_types";
import { DashboardType } from "../types/dashboard_types";
import {
  BinaryDTO,
  Document,
  MatchingDTO,
  USER_STATE,
} from "../types/generic_types";
import { WalletType } from "../types/wallet_types";
import { Vouchers } from "../types/voucher_types";
import { HubspotTrackingToken } from "../types/hubspot_types";
import { sha256 } from "js-sha256";

export default abstract class API_CLIENT {
  static baseAddress: string | undefined = process.env.REACT_APP_BFF_API;
  static voucherifyId: string | undefined = process.env.REACT_APP_VOUCHERIFY_ID;
  static voucherifyToken: string | undefined =
    process.env.REACT_APP_VOUCHERIFY_TOKEN;

  static getHubspotTrackingToken(
    token: string,
    onSuccess: (token: HubspotTrackingToken) => void,
    onError: (error: String) => void
  ) {
    fetch(`${this.baseAddress}/api/hubspot/visitorident`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 503) {
          throw Error(res.statusText);
        } else if (res.status === 200) {
          return res.json();
        }
      })
      .then((response: HubspotTrackingToken) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static postMatching(token: String, matchingDTO: MatchingDTO) {
    var em = matchingDTO.em;
    var ph = matchingDTO.ph;
    var fn = matchingDTO.fn;
    var ln = matchingDTO.ln;

    if (em) matchingDTO.em = sha256(em);
    if (ph) matchingDTO.ph = sha256(ph);
    if (fn) matchingDTO.fn = sha256(fn.toLowerCase());
    if (ln) matchingDTO.ln = sha256(ln.toLowerCase());

    fetch(`${this.baseAddress}/api/tr`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(matchingDTO),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw Error("error");
        }
      })
      .catch((err) => err);
  }

  static getUser(
    token: string,
    onSuccess: (user: InventureUser) => void,
    onError: (errorMessage: string) => void
  ) {
    fetch(`${this.baseAddress}/api/user`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 503) {
          throw Error(res.statusText);
        } else if (res.status === 200) {
          return res.json();
        }
      })
      .then((response: InventureUser) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static hasMFA(
    token: string,
    onSuccess: (result: boolean) => void,
    onError: (errorMessage: string) => void
  ) {
    fetch(`${this.baseAddress}/api/user/mfa`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 503) {
          throw Error(res.statusText);
        } else if (res.status === 200) {
          return res.json();
        }
      })
      .then((result: boolean) => {
        onSuccess(result);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static enrollMFA(
    token: string,
    onSuccess: () => void,
    onError: (errorMessage: string) => void
  ) {
    fetch(`${this.baseAddress}/api/user/mfa-enrollment`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 503) {
          throw Error(res.statusText);
        } else if (res.status === 200) {
          return res.json();
        }
      })
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      });
  }

  static postRiskConfimed(
    token: string,
    onSuccess: () => void,
    onError: (erorr: String) => void
  ) {
    fetch(`${this.baseAddress}/api/user/risk-confirmed`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 503) {
          throw Error(res.statusText);
        } else if (res.status === 200) {
          return res.json();
        }
      })
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      });
  }

  static getUserDocuments(
    token: string,
    onSuccess: (documents: Document[]) => void,
    onError: (errorMessage: string) => void
  ) {
    fetch(`${this.baseAddress}/api/user/documents`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 503) {
          throw Error(res.statusText);
        } else if (res.status === 200) {
          return res.json();
        }
      })
      .then((response: Document[]) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static getWallet(
    token: string,
    onSuccess: (documents: WalletType) => void,
    onError: (errorMessage: string) => void
  ) {
    fetch(`${this.baseAddress}/api/wallet`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 503) {
          throw Error(res.statusText);
        } else if (res.status === 200) {
          return res.json().then((response: WalletType) => {
            onSuccess(response);
          });
        }
      })
      .catch((error) => {
        onError(error);
      });
  }

  static getVouchers(
    token: string,
    onSuccess: (vouchers: Vouchers) => void,
    onError: (errorMessage: string) => void
  ) {
    fetch(`${this.baseAddress}/api/user/vouchers`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status > 300) {
          throw Error(res.statusText);
        } else if (res.status === 200) {
          return res.json().then((response: Vouchers) => {
            onSuccess(response);
          });
        }
      })
      .catch((error) => {
        onError(error);
      });
  }

  static signup(
    signupData: SignupData,
    token: string,
    onSuccess: () => void,
    onError: (errorMessage: string) => void
  ) {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("token", token);
    fetch(`${this.baseAddress}/extern/api/signup`, {
      method: "POST",
      headers: requestHeaders,
      body: JSON.stringify(signupData),
    })
      .then((res) => {
        if (res.status < 300) {
          onSuccess();
        }
        return res.json();
      })
      .then((error) => {
        onError(error);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static getDashboard(
    token: string,
    onSuccess: (dashboard: DashboardType) => void,
    onError: (error: string) => void
  ) {
    fetch(`${this.baseAddress}/api/dashboard`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status >= 300) {
          onError(res.statusText);
          throw Error("no investments done");
        }
        return res.json();
      })
      .then((dashboard) => {
        onSuccess(dashboard);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static async getLastIncomingInvestment(
    token: string,
    userId: String,
    onSuccess: (investment: Investment) => void,
    onError: () => void
  ) {
    fetch(`${this.baseAddress}/api/investment/last-incoming`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        if (res.status === 404) {
          throw new Error("Not found");
        } else {
          throw new Error("Error");
        }
      })
      .then((investment) => {
        onSuccess(investment);
      })
      .catch((error: Error) => {
        onError();
        console.log("fetch investment failed");
      });
  }

  static getInvestmentByCampaign(
    token: string,
    campaignId: String,
    onSuccess: (investment: Investment) => void,
    onNoInvestmentFound: () => void,
    onError: (errorMessage: string) => void
  ) {
    fetch(`${this.baseAddress}/api/campaign/${campaignId}/investment`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        if (res.status === 404) {
          throw new Error("Not found");
        } else {
          throw new Error("Error");
        }
      })
      .then((investment) => {
        onSuccess(investment);
      })
      .catch((error: Error) => {
        if (error.message === "Not found") {
          onNoInvestmentFound();
        } else {
          onError(error.message);
        }
      });
  }

  static async getInvestmentsByCampaign(
    token: string,
    campaignId: String,
    onSuccess: (InvestmentList: Investment[]) => void,
    onNoInvestmentFound: () => void,
    onError: (errorMessage: string) => void
  ) {
    fetch(`${this.baseAddress}/api/campaign/${campaignId}/investments`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        if (res.status === 404) {
          throw new Error("Not found");
        } else {
          throw new Error("Error");
        }
      })
      .then((investment) => {
        onSuccess(investment);
      })
      .catch((error: Error) => {
        if (error.message === "Not found") {
          onNoInvestmentFound();
        } else {
          onError(error.message);
        }
      });
  }

  static getInvestment(
    token: string,
    investmentId: String,
    onSuccess: (investment: Investment) => void,
    onNoInvestmentFound: () => void,
    onError: (errorMessage: string) => void
  ) {
    fetch(`${this.baseAddress}/api/investment/${investmentId}`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        if (res.status === 404) {
          throw new Error("Not found");
        } else {
          throw new Error("Error");
        }
      })
      .then((investment) => {
        onSuccess(investment);
      })
      .catch((error: Error) => {
        if (error.message === "Not found") {
          onNoInvestmentFound();
        } else {
          onError(error.message);
        }
      });
  }

  static getInvestmentDocs(
    token: string,
    id: string,
    getDocuments: (docs: Document[]) => void
  ) {
    fetch(this.baseAddress + "/api/investment/" + id + "/documents/v2", {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Error");
        }
      })
      .then((response: Document[]) => {
        getDocuments(response);
      });
  }

  static getInvestmentDocsV1(
    token: string,
    id: string,
    getDocuments: (docs: Document[]) => void
  ) {
    fetch(this.baseAddress + "/api/investment/" + id + "/documents/v1", {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Error");
        }
      })
      .then((response: Document[]) => {
        getDocuments(response);
      });
  }

  static postInvestmentAmount(
    CreateInvestmentDTO: CreateInvestmentDTO,
    token: string,
    amount: number,
    campaignId: string,
    onSuccess: (investment: Investment) => void,
    onError: (errorMessage: string) => void,
    onKeyNotFound: () => void,
    onKeyAlreadyUsed: () => void
  ) {
    fetch(
      `${this.baseAddress}/api/campaign/${campaignId}/investment/units/${amount}`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(CreateInvestmentDTO),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 404) {
          onKeyNotFound();
          throw Error("KeyNotFound");
        } else if (res.status === 400) {
          onKeyAlreadyUsed();
          throw Error("KeyAlreadyUsed");
        } else {
          throw new Error("Error");
        }
      })
      .then((response: Investment) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static putInvestmentAmount(
    createInvestmentDTO: CreateInvestmentDTO,
    token: string,
    amount: number,
    investmentId: string,
    onSuccess: (investment: Investment) => void,
    onError: (errorMessage: string) => void,
    onKeyNotFound: () => void,
    onKeyAlreadyUsed: () => void
  ) {
    fetch(
      `${this.baseAddress}/api/investment/${investmentId}/units/${amount}`,
      {
        method: "PUT",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(createInvestmentDTO),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 404) {
          onKeyNotFound();
          throw Error("KeyNotFound");
        } else if (res.status === 400) {
          onKeyAlreadyUsed();
          throw Error("KeyAlreadyUsed");
        } else {
          throw new Error("Error");
        }
      })
      .then((response: Investment) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static putInvestmentCostAccepted(
    token: string,
    investmentId: string,
    onSuccess: (investment: Investment) => void,
    onError: (investment: Investment) => void
  ) {
    fetch(`${this.baseAddress}/api/investment/${investmentId}/cost-accepted`, {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error("Error");
        }
      })
      .then((response: Investment) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static putInvestmentWalletAccepted(
    token: string,
    investmentId: string,
    onSuccess: (investment: Investment) => void,
    onError: (investment: Investment) => void
  ) {
    fetch(
      `${this.baseAddress}/api/investment/${investmentId}/wallet-accepted`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error("Error");
        }
      })
      .then((response: Investment) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static putInvestmenDataProtection(
    token: string,
    investmentId: string,
    onSuccess: (investment: Investment) => void,
    onError: (err: any) => void,
    no_sharing: boolean
  ) {
    var queries = "?data-protection-sharing-disallowed=" + no_sharing;
    fetch(
      `${this.baseAddress}/api/investment/${investmentId}/data-protection${queries}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error("Error");
        }
      })
      .then((response: Investment) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static makeBindingOfferV3(
    token: string,
    investmentId: string,
    onSuccess: (investment: Investment) => void,
    onError: (error: string) => void,
    code?: string
  ) {
    var queries = "";
    if (code) {
      queries += "?code=" + code;
    }

    fetch(
      `${this.baseAddress}/api/investment/${investmentId}/offerv3${queries}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          return res.json();
        } else {
          throw new Error("Error");
        }
      })
      .then((response: Investment) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static makeBindingOfferV3Legal(
    token: string,
    investmentId: string,
    onSuccess: (investment: Investment) => void,
    onError: (error: string) => void,
    code?: string
  ) {
    var queries = "";
    if (code) {
      queries = "?code=" + code;
    }
    fetch(
      `${this.baseAddress}/api/investment/${investmentId}/offerv3-legal${queries}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          return res.json();
        } else {
          throw new Error("Error");
        }
      })
      .then((response: Investment) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static abortInvestment(
    token: string,
    investmentId: string,
    onSuccess: (investment: Investment) => void,
    onError: (error: string) => void
  ) {
    fetch(`${this.baseAddress}/api/investment/${investmentId}/abort`, {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error("Error");
        }
      })
      .then((response: Investment) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static waitingForYousignInvestment(
    token: string,
    investmentId: string,
    onSuccess: (investment: Investment) => void,
    onError: (error: string) => void
  ) {
    fetch(
      `${this.baseAddress}/api/investment/${investmentId}/waiting-for-yousign`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error("Error");
        }
      })
      .then((response: Investment) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static putInvestmentSurvey(
    token: string,
    investmentId: string,
    survey: Survey,
    surveyScoreTooLow: () => void,
    surveyHasEmptyFields: () => void,
    onSuccess: (investment: Investment) => void,
    onError: (error: string) => void
  ) {
    fetch(`${this.baseAddress}/api/investment/${investmentId}/survey`, {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(survey),
    })
      .then((res) => {
        if (res.status === 201) {
          return res.json().then((investment: Investment) => {
            onSuccess(investment);
          });
        }
        if (res.status === 406) {
          res
            .json()
            .then((body) => {
              if (body.message === "Survey has empty fields") {
                throw Error("Survey has empty fields");
              }
              if (body.message === "Investor score too low") {
                throw Error("Investor score too low");
              }
            })
            .catch((err: Error) => {
              if (err.message === "Survey has empty fields") {
                surveyHasEmptyFields();
              }
              if (err.message === "Investor score too low") {
                surveyScoreTooLow();
              }
            });
        } else {
          throw Error("Error");
        }
      })
      .catch((err: Error) => {
        onError(err.message);
      });
  }

  static getSignedDocuments(
    token: string,
    investmentId: string,
    onSuccess: (documents: SignedDocument[]) => void,
    onError: (error: String) => void
  ) {
    fetch(`${this.baseAddress}/api/investment/${investmentId}/documents`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => res.json())
      .then((response: SignedDocument[]) => {
        onSuccess(response);
      })
      .catch((error) => onError(error));
  }

  static putSurveyWaiver(
    token: string,
    investmentId: string,
    waiverType: WAIVER_TYPE,
    onSuccess: (investment: Investment) => void,
    onError: (error: string) => void
  ) {
    fetch(
      `${this.baseAddress}/api/investment/${investmentId}/survey/waiver/${waiverType}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw Error("Error");
        }
      })
      .then((investment: Investment) => {
        onSuccess(investment);
      })
      .catch((err: Error) => {
        onError(err.message);
      });
  }

  static putBankAccount(
    token: string,
    bankAccount: BankAccount,
    onSuccess: (user: InventureUser) => void,
    onError: (errorMessage: string) => void
  ) {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${token}`);

    fetch(`${this.baseAddress}/api/user/bank-account`, {
      method: "PUT",
      headers: requestHeaders,
      body: JSON.stringify(bankAccount),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw Error("Error");
        }
      })
      .then((user: InventureUser) => {
        onSuccess(user);
      })
      .catch((err: Error) => {
        onError(err.message);
      });
  }

  static putTax(
    token: string,
    tax: TaxInformation,
    onSuccess: (user: InventureUser) => void,
    onError: (errorMessage: string) => void
  ) {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${token}`);

    fetch(`${this.baseAddress}/api/user/tax`, {
      method: "PUT",
      headers: requestHeaders,
      body: JSON.stringify(tax),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw Error("Error");
        }
      })
      .then((user: InventureUser) => {
        onSuccess(user);
      })
      .catch((err: Error) => {
        onError(err.message);
      });
  }
  static putBankAndTax(
    token: string,
    data: BankAndTax,
    onSuccess: (user: InventureUser) => void,
    onError: (errorMessage: string) => void
  ) {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${token}`);

    fetch(`${this.baseAddress}/api/user/bank-and-tax`, {
      method: "PUT",
      headers: requestHeaders,
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw Error("Error");
        }
      })
      .then((user: InventureUser) => {
        onSuccess(user);
      })
      .catch((err: Error) => {
        onError(err.message);
      });
  }

  static getFunds(
    token: string,
    onSuccess: (funds: Fund[]) => void,
    onError: (error: String) => void
  ) {
    var headers = new Headers();
    headers.set("Authorization", `Bearer ${token}`);

    fetch(`${this.baseAddress}/api/funds`, {
      method: "GET",
      headers: headers,
    })
      .then((res) => res.json())
      .then((response: Fund[]) => {
        onSuccess(response);
      })
      .catch((error) => onError(error));
  }

  static getFund(
    token: string,
    name: string,
    onSuccess: (fund: Fund) => void,
    onError: (error: string) => void
  ) {
    fetch(`${this.baseAddress}/api/fund/${name}`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        if (res.status === 404) {
          throw Error("Fund not found");
        }
      })
      .then((response: Fund) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static async getFundNoAuth(
    name: string,
    onSuccess: (fund: Fund) => void,
    onError: (error: string) => void
  ) {
    fetch(`${this.baseAddress}/extern/api/fund/${name}`, {
      method: "GET",
      headers: new Headers({}),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        if (res.status === 404) {
          throw Error("Fund not found");
        }
      })
      .then((response: Fund) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static getCamapign(
    token: string,
    id: string,
    onSuccess: (campaign: Campaign) => void,
    onError: (error: string) => void
  ) {
    fetch(`${this.baseAddress}/api/campaign/${id}`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json().then((response: Campaign) => {
            onSuccess(response);
          });
        }
        if (res.status === 404) {
          throw Error("Fund not found");
        }
        if (res.status === 429) {
          window.location.href = "/error?message=Too many requests";
        }
      })
      .catch((error) => {
        onError(error);
      });
  }

  static putOnboarding(
    token: String,
    onboardingData: OnboardingData,
    onSuccess: (user: InventureUser) => void,
    onError: (error: String) => void
  ) {
    fetch(`${this.baseAddress}/api/user/onboard`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(onboardingData),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw Error("error");
        }
      })
      .then((user: InventureUser) => {
        onSuccess(user);
      })
      .catch((err) => onError(err));
  }

  static putOnboardingZero(
    token: string,
    onboardFromZero: OnboardingZero,
    onSuccess: (user: InventureUser) => void,
    onError: (errorMessage: string) => void
  ) {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${token}`);
    fetch(`${this.baseAddress}/api/user/onboard-zero`, {
      method: "PUT",
      headers: requestHeaders,
      body: JSON.stringify(onboardFromZero),
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw Error("error");
        }
      })
      .then((user) => {
        onSuccess(user);
      })
      .catch((error) => {
        onError(error);
      });
  }

  static getUserState(
    token: String,
    onSuccess: (state: USER_STATE) => void,
    onError: (error: string) => void
  ) {
    fetch(`${this.baseAddress}/api/user/state`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json().then((userstate) => {
            onSuccess(userstate);
          });
        } else if (response.status === 429) {
          window.location.href =
            "/error?error=Von_dir_sind_zu_viele_Zugriffe_gekommen._Bitte_warte_ein_wenig";
        } else {
          throw Error("unknown error");
        }
      })
      .catch((error) => onError(error));
  }

  static validateVoucher(
    customerId: string,
    amount: number,
    code: string,
    onSuccess: (voucher: Voucher) => void,
    onError: (error: String) => void
  ) {
    if (this.voucherifyId == undefined) return;
    if (this.voucherifyToken == undefined) return;
    fetch(
      `https://api.voucherify.io/client/v1/validate?code=${code}&amount=${amount}&customer[source_id]=${customerId}`,
      {
        method: "GET",
        headers: new Headers({
          "X-Client-Application-Id": this.voucherifyId,
          "X-Client-Token": this.voucherifyToken,
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw Error("token error");
        }
      })
      .then((voucher: Voucher) => {
        onSuccess(voucher);
      })
      .catch((error) => onError(error));
  }

  static validateVoucherV2(
    token: string,
    code: string,
    campaign_id: string,
    onSuccess: (voucher: Voucher) => void,
    onError: (error: String) => void
  ) {
    if (this.voucherifyId == undefined) return;
    if (this.voucherifyToken == undefined) return;
    fetch(
      `${this.baseAddress}/api/voucher/validate/${code}/product/${campaign_id}`,
      {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw Error("token error");
        }
      })
      .then((voucher: Voucher) => {
        onSuccess(voucher);
      })
      .catch((error) => onError(error));
  }

  static resendVerificationMail(
    token: string,
    onSuccess: () => void,
    onError: () => void
  ) {
    fetch(`${this.baseAddress}/api/user/verification`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          onSuccess();
          return;
        } else {
          throw Error("unknown error");
        }
      })
      .catch((error) => onError());
  }

  static getDocument(token: string, fund: string, name: string) {
    fetch(`${this.baseAddress}/api/fund/${fund}/document/${name}`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        responseType: "blob",
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.blob();
        } else {
          throw Error("unknown error");
        }
      })
      .then((blob) => {
        const file = new Blob([blob], { type: "application/pdf" });

        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL, "_blank");
      })
      .catch((error) => console.log(error));
  }

  static postLegalPerson(
    token: string,
    onSuccess: (user: InventureUser) => void,
    person: LegalPerson
  ) {
    fetch(`${this.baseAddress}/api/user/legal-person`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(person),
    })
      .then((res) => {
        if (res.status >= 300) {
          throw Error("failed posting legal person");
        }
        return res.json();
      })
      .then((user: InventureUser) => {
        onSuccess(user);
      })
      .catch((error) => console.log(error));
  }

  static postLegalPersonFiles(
    token: string,
    onSuccess: () => void,
    structureFile: File,
    identityFile: File
  ) {
    const formData = new FormData();
    formData.append("company-identity-proof", identityFile);
    formData.append("company-structure-proof", structureFile);
    fetch(`${this.baseAddress}/api/user/legal-person/company-files`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
      body: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          onSuccess();
        } else {
          throw Error("unknown error");
        }
      })
      .catch((error) => console.log(error));
  }

  static postBenefitingPerson(
    token: string,
    onSuccess: (user: InventureUser) => void,
    person: BenefitingPerson
  ) {
    var persons = [person];
    fetch(`${this.baseAddress}/api/user/benefiting-persons`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(persons),
    })
      .then((res) => {
        if (res.status >= 300) {
          throw Error("failed posting legal person");
        }
        return res.json();
      })
      .then((user: InventureUser) => {
        onSuccess(user);
      })
      .catch((error) => console.log(error));
  }

  // -- Dinesh Kumar
  static putAddress(
    token: string,
    address: Address,
    onSuccess: () => void,
    onError: (errorMessage: string) => void
  ) {
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set("Content-Type", "application/json");
    requestHeaders.set("Authorization", `Bearer ${token}`);

    fetch(`${this.baseAddress}/api/user/update/address`, {
      method: "PUT",
      headers: requestHeaders,
      body: JSON.stringify(address),
    })
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      });
  }

  static postInvestmentDoc(
    token: string,
    id: string,
    file: File,
    name: string,
    description: string,
    onSuccess: () => void
  ) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name);
    formData.append("description", description);

    fetch(`${this.baseAddress}/api/investment/${id}/document`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          onSuccess();
          return res.json(); // Expecting JSON response from the server
        } else {
          throw new Error("Error uploading file");
        }
      })
      .then((docs) => {})
      .catch((error) => {
        console.log(error);
      });
  }

  //Deprecated ########################################################################################################################################################################################
  static makeBindingOffer(
    token: string,
    investmentId: string,
    onSuccess: (investment: Investment) => void,
    onError: (error: string) => void
  ) {
    fetch(`${this.baseAddress}/api/investment/${investmentId}/offer`, {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          return res.json();
        } else {
          throw new Error("Error");
        }
      })
      .then((response: Investment) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }
  static makeBindingOfferV2(
    token: string,
    investmentId: string,
    onSuccess: (investment: Investment) => void,
    onError: (error: string) => void
  ) {
    fetch(`${this.baseAddress}/api/investment/${investmentId}/offerv2`, {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          return res.json();
        } else {
          throw new Error("Error");
        }
      })
      .then((response: Investment) => {
        onSuccess(response);
      })
      .catch((error) => {
        onError(error);
      });
  }
}
