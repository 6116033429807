import { useAuth0 } from "@auth0/auth0-react";
import {
  DonutChart,
  TransactionList,
} from "@inventurefinancial/frontend-components";
import { Divider, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import TagManager from "react-gtm-module";
import { v4 } from "uuid";
import PrimaryButton, {
  PrimaryButtonV2,
  SecondaryButtonV2,
} from "../../../components/input-elements/buttons";
import LoadingScreen from "../../../components/loading-screen";
import theme from "../../../theme";
import { DashboardType } from "../../../types/dashboard_types";
import {
  CAMPAIGN_TYPE,
  Fund,
  FUND_STAGE,
  FUND_STATUS,
  PROVIDER,
} from "../../../types/fund_types";
import { MatchingDTO, USER_STATE } from "../../../types/generic_types";
import { Investment, INVESTMENT_STATE } from "../../../types/investment_types";
import { News } from "../../../types/vc-platform/news_types";
import API_CLIENT from "../../../utility/api-client";
import NEWS_CLIENT from "../../../utility/news-client";
import {
  INVESTMENT_TYPE,
  Transaction as TransactionType,
} from "../../portal/dashboard/transaction";
import queryString from "query-string";
import { AppContext } from "../direct-router";
import { WalletPositionV2 } from "../../../components/page-components/dashboard/walletPosition";
import { Link } from "react-router-dom";
import { Fund as VCPlatformFund } from "../../../types/vc-platform/news_types";
import { InvestmentStatusChipV2 } from "../../../components/data-display/investment-status-chip";
import { InvestmentDetailsDialog } from "../investment-details/investment-details";
import CopyText, {
  CopyTextV2,
} from "../../../components/data-display/copy-text";
import IBAN from "iban";
import AmountUtils from "../../../utility/amount-utils";
import PostIdentButton from "../../../components/page-components/onboarding/postident-button";
import IVDrawer from "../../../components/template-components/iv-drawer";
import VideoIdent from "./videoident";
import { Helmet } from "react-helmet";

import YouSignDialog from "../../../components/dialogs/yousign-dialog";
import InvestmentDetailsDrawer from "./investment-details-drawer";

export function Dashboard() {
  const queries = queryString.parse(window.location.search);
  const investment_id = queries.investment_id
    ? queries.investment_id.toString()
    : null;

  const [dashboard, setDashboard] = useState<DashboardType>();
  const [funds, setFunds] = useState<Fund[]>();
  const [vcplatform_funds, setVcPlatform_funds] = useState<VCPlatformFund[]>();
  const [successfullInvestments, setSuccessfullInvestments] =
    useState<Investment[]>();
  const [transactions, setTransactions] = useState<TransactionType[]>();
  const [wallet, setWallet] = useState<Wallet>();
  const appContext = useContext(AppContext);
  const [news, setNews] = useState<News[]>();
  const [active_investment_id, setActiveInvestmentId] = useState<null | string>(
    investment_id
  );
  const [videoident_open, setVideoIdentOpen] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [scroll_top, setScrollTop] = useState(0);
  const [scroll_left, setScrollLeft] = useState(0);

  let callId = v4();

  type Position = {
    name: string;
    amount: string;
  };

  useEffect(() => {
    const uData = appContext.matchingData;
    TagManager.dataLayer({
      dataLayer: {
        event: "view-dashboard",
        event_id: callId,
        first_name: appContext.user?.natural_person?.forename,
        last_name: appContext.user?.natural_person?.surname,
        phone: uData?.ph,
        email: appContext.userEmail,
      },
    });
    window.scrollTo(0, 0);
    const getDashboard = async () => {
      const uData = appContext.matchingData;
      if (uData == null) return;
      const accessToken = await getAccessTokenSilently({});
      var mData: MatchingDTO = {
        event: "ViewContent",
        eventId: callId,
        sourceUrl: window.location.href,
        em: uData?.em,
        ph: uData?.ph,
        fn: uData?.fn,
        ln: uData?.ln,
      };
      API_CLIENT.postMatching(accessToken, mData);
      API_CLIENT.getDashboard(
        accessToken,
        (dashboard: DashboardType) => {
          setDashboard(dashboard);
          setSuccessfullInvestments(
            dashboard?.investments
              ?.filter(
                (investment) =>
                  investment.state == INVESTMENT_STATE.ACCEPTED ||
                  investment.state == INVESTMENT_STATE.DELIVERED ||
                  investment.state == INVESTMENT_STATE.KYC_INVALID ||
                  investment.state == INVESTMENT_STATE.KYC_PENDING ||
                  investment.state == INVESTMENT_STATE.PAID ||
                  investment.state == INVESTMENT_STATE.WAITING_FOR_ACCEPTANCE ||
                  investment.state == INVESTMENT_STATE.SIGNATURE_PENDING ||
                  investment.state == INVESTMENT_STATE.SIGNED
              )
              .sort((a, b) => {
                var a_time = a.acceptedAt ? a.acceptedAt : a.offerCreatedAt;
                var b_time = b.acceptedAt ? b.acceptedAt : b.offerCreatedAt;

                return new Date(b_time).getTime() - new Date(a_time).getTime();
              })
          );
        },
        (error: string) => {
          setDashboard({
            coInvestments: [],
            investments: [],
            tokens: [],
          });
        }
      );
      API_CLIENT.getFunds(
        accessToken,
        (funds: Fund[]) => {
          setFunds(funds);
        },
        () => {}
      );
    };
    getDashboard();
  }, []);

  useEffect(() => {
    if (dashboard == undefined || dashboard == null) return;
    const successfullInvestments = dashboard.investments
      ?.filter(
        (investment) =>
          investment.state == INVESTMENT_STATE.ACCEPTED ||
          investment.state == INVESTMENT_STATE.DELIVERED ||
          investment.state == INVESTMENT_STATE.KYC_INVALID ||
          investment.state == INVESTMENT_STATE.KYC_PENDING ||
          investment.state == INVESTMENT_STATE.PAID ||
          investment.state == INVESTMENT_STATE.WAITING_FOR_ACCEPTANCE ||
          investment.state == INVESTMENT_STATE.SIGNATURE_PENDING ||
          investment.state == INVESTMENT_STATE.SIGNED ||
          investment.state == INVESTMENT_STATE.WAITING_FOR_YOUSIGN
      )
      .map((investment) => {
        const t: TransactionType = {
          type: INVESTMENT_TYPE.PLATFORM,
          id: investment.id,
          fundId: investment.fundId,
          fundName: investment.fundName,
          campaignId: investment.campaignId,
          state: investment.state,
          signedAt: investment.signedAt ? new Date(investment.signedAt) : null,
          offerCreatedAt: investment.offerCreatedAt
            ? new Date(investment.offerCreatedAt)
            : null,
          amount: investment.amount,
        };
        return t;
      });

    const coInvestments = dashboard.coInvestments?.map((coinv) => {
      const t: TransactionType = {
        type: INVESTMENT_TYPE.COINVEST,
        id: coinv.id,
        fundId: coinv.fundId,
        fundName: coinv.fundName,
        campaignId: coinv.campaignId,
        signedAt: new Date(coinv.investedOn),
        offerCreatedAt: null,
        amount: coinv.amount,
        documents: coinv.documents,
      };
      return t;
    });
    const allTransactions = successfullInvestments
      .concat(coInvestments)
      .sort((a, b) => {
        var a_time = a.signedAt
          ? a.signedAt.getTime()
          : a.offerCreatedAt
          ? a.offerCreatedAt.getTime()
          : 0;
        var b_time = b.signedAt
          ? b.signedAt.getTime()
          : b.offerCreatedAt
          ? b.offerCreatedAt.getTime()
          : 0;

        return b_time - a_time;
      });
    setTransactions(allTransactions);
    /**.sort(
            (a, b) =>
              new Date(b.signedAt).getTime() -
              new Date(a.signedAt).getTime()
          ) */
  }, [dashboard]);

  useEffect(() => {
    if (transactions && funds) {
      setWallet(new Wallet(transactions, funds));
    }
  }, [transactions, funds]);

  useEffect(() => {
    var fundIds = wallet?.funds.map((fund) => fund.targetInvestmentId);
    if (!fundIds) fundIds = [];

    //for debugging new news, this switch allows to show all news by adding ?allnews=true to the query

    NEWS_CLIENT.getFunds(fundIds, (funds: VCPlatformFund[]) => {
      if (funds) {
        setVcPlatform_funds(funds);
      }
    });
  }, [wallet]);

  useEffect(() => {
    if (active_investment_id) {
      disableScroll1();
    } else {
      enableScroll1();
    }
  }, [active_investment_id]);

  if (!dashboard || !funds || !wallet || !transactions)
    return <LoadingScreen className="w-full h-screen" />;

  //creating investment lists
  const actionRequiredInvestments = dashboard?.investments?.filter(
    (investment) =>
      investment.state == INVESTMENT_STATE.ACCEPTED ||
      investment.state == INVESTMENT_STATE.SIGNATURE_PENDING
  );

  // if (!transactions || transactions.length == 0) {
  //   return (
  //     <div style={{ display: "flex", justifyContent: "center" }}>
  //       <div
  //         style={{
  //           width: "1200px",
  //           minHeight: "calc(100vh - 80px)",
  //           backgroundSize: "contain",
  //           backgroundRepeat: "no-repeat",
  //           display: "flex",
  //           justifyContent: "center",
  //           backgroundPosition: "center",
  //         }}
  //       >
  //         <div
  //           style={{
  //             maxWidth: "500px",
  //             width: "90%",
  //             minWidth: "300px",
  //             height: "200px",
  //             textAlign: "center",
  //             marginTop: "200px",
  //             marginBottom: "200px",
  //             textShadow: "0 0 5px white",
  //           }}
  //         >
  //           <Typography variant="h5">
  //             <b style={{}}>Dashboard</b>
  //           </Typography>
  //           <Typography
  //             variant="body1"
  //             style={{ marginTop: "10px", marginBottom: "20px" }}
  //           >
  //             <b>
  //               Hier wirst Du, sobald Du eine Investition bei uns getätigt hast,
  //               alle relevanten Informationen finden. Schaue gerne bei unseren
  //               Produkten vorbei.
  //             </b>
  //           </Typography>
  //           <Link to="/portal/products" style={{ textDecoration: "none" }}>
  //             <PrimaryButton
  //               style={{
  //                 height: "50px",
  //                 margin: "auto auto",
  //                 padding: "0 30px",
  //               }}
  //             >
  //               Zu den Produkten
  //             </PrimaryButton>
  //           </Link>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  const pendingInvestments = dashboard?.investments?.filter(
    (investment) => investment.state == INVESTMENT_STATE.PENDING
  );
  const last_pending = pendingInvestments.pop();
  const last_pending_fund = funds.find(
    (fund) => fund.cashlinkId == last_pending?.fundId
  );
  const last_pending_campaign = last_pending_fund?.cashlinkCampaigns.find(
    (campaign) => campaign.id == last_pending?.campaignId
  );
  const is_last_pending_fund_active =
    last_pending_fund?.fundStatus == FUND_STATUS.OPEN;

  const needs_videoident = appContext.userState == USER_STATE.ONBOARDED_1;

  const portfolioSeries = {
    series: wallet?.positions?.map((positions) => positions.amount),
  };
  const stageSeries = {
    series: wallet?.positionsByStage?.map((position) => position.amount),
  };
  const investment_sum = wallet?.positions
    .map((position) => position.amount)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const lastPendingIsActive =
    funds.find((fund) => fund.id === last_pending?.fundId)?.fundStatus ==
    FUND_STATUS.OPEN;

  function disableScroll() {
    // Get the current page scroll position in the vertical direction
    var scrollTop = window.scrollY || document.documentElement.scrollTop;

    // Get the current page scroll position in the horizontal direction

    var scrollLeft = window.screenX || document.documentElement.scrollLeft;

    // if any scroll is attempted,
    // set this to the previous value
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
    document.body.style.position = "fixed";
    document.body.style.position = "";
  }

  function enableScroll() {
    window.onscroll = function () {};
  }

  function disableScroll1() {
    // Store the current scroll position
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

    setScrollTop(scrollTop);
    setScrollLeft(scrollLeft);

    // Apply styles to disable scrolling and hide the scrollbar
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollTop}px`;
    document.body.style.left = `-${scrollLeft}px`;
    document.body.style.width = "100%";
    document.body.style.overflow = "hidden";
  }

  function enableScroll1() {
    // Remove styles to enable scrolling and show the scrollbar
    document.body.style.position = "";
    document.body.style.top = "";
    document.body.style.left = "";
    document.body.style.width = "";
    document.body.style.overflow = "";
    window.scrollTo(scroll_left, scroll_top);
  }

  const HeaderSectionNew = () => {
    return (
      <div className="w-full px-4 py-0  flex gap-8 justify-center">
        <div className="w-full max-w-6xl my-4 md:my-16 flex flex-col gap-4 md:gap-16 ">
          <div className="flex flex-col">
            <div
              className={`w-full h-auto md:h-16 flex justify-center items-center`}
            >
              <div className="w-full grid gap-2">
                {appContext.user?.natural_person ? (
                  <h1 className="text-4xl sm:text-5xl text-primary font-headline">
                    Willkommen,{" "}
                    <b>{appContext.user?.natural_person.forename}</b>
                  </h1>
                ) : (
                  <h1 className="text-3xl">Willkommen</h1>
                )}
                <p className="font-normal text-gray-604 text-lg text-primary_light">
                  Hier findest du eine Übersicht deiner bisherigen Investitionen
                  sowie unser aktuelles Produkt.
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-start gap-8">
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
              {needs_videoident ? <VideoIdent /> : null}
              <div className="bg-cover rounded-xl flex flex-col overflow-hidden">
                <div className="px-4 py-4 bg-[#E2EAF2] h-full flex flex-col">
                  <p className="text-sm text-primary text-opacity-80 font-normal align-">
                    Bisher investiert
                  </p>
                  <div className="flex-grow" />
                  <p className="text-primary text-3xl md:text-3xl font-semibold mt-4">
                    {investment_sum?.toLocaleString("de-de")}€
                  </p>
                </div>
              </div>

              {/* {last_pending && is_last_pending_fund_active ? (
                <div className="group  overflow-hidden w-full  bg-[#E2EAF2]  rounded-xl flex flex-col gap-0">
                  <Link
                    to={`/direct/investment?campaign=${last_pending.campaignId}&fund=${last_pending.fundId}`}
                    className="flex flex-col h-full justify-between"
                  >
                    <div className="flex flex-col  p-4">
                      <div>
                        <p className="text-sm text-black font-medium leading-3">
                          Investition fortsetzen
                        </p>
                        <p className="text-xl text-black font-semibold">
                          {last_pending.fundName}
                        </p>
                      </div>
                    </div>
                    <div className="flex-grow" />
                    <div className="bg-primary py-2 pl-4 pr-4 text-white flex">
                      <img
                        src="https://ik.imagekit.io/inventure/App/Icons/icons8-sales-performance-90_uk3y-UCAj.png?updatedAt=1689266363135"
                        className="h-5 mr-2"
                      />
                      <p className="text-sm font-medium group-hover:ml-2 transition-all">
                        fortsetzen
                      </p>
                      <div className="flex-grow"></div>
                      <div className="flex text-sm">
                        <p className="mr-1 group-hover:mr-0 transition-all">
                          {">"}
                        </p>
                        <p className="mr-1 group-hover:mr-0  transition-all">
                          {">"}
                        </p>
                        <p>{">"}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              ) : null} */}
              <div className="col-span-1 md:col-span-2 gap-4">
                <div className="bg-center overflow-hidden relative col-span-4 md:col-span-2 lg:col-span-2 w-full bg-cover rounded-xl flex flex-col bg-gradient-to-br from-[#8895a3] to-[#ffbc80] border-primary">
                  <div className="w-full h-full  px-4 py-4 flex flex-col items-start gap-4">
                    <div>
                      <p className="text-white text-sm  md:text-sm">
                        iVC Venture Innovation Fund
                      </p>
                      <h2 className="text-white text-xl md:text-xl ">
                        Investiere in ein ausgewähltes Portfolio an europäischen
                        Venture Capital Fonds
                      </h2>
                    </div>
                    <PrimaryButtonV2 href="/direct/start-investment?campaign=84e81b94-b01e-4a54-b880-43db338575bf&fund=f2fa67e5-e1ad-4e1d-87d3-ce3c94a1549f">
                      Jetzt investieren
                    </PrimaryButtonV2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const WalletSection = () => {
    const [active_section, setActiveSection] = useState(0);
    const transactions_active = active_section == 0;
    const positions_active = active_section == 1;

    return (
      <div className={`flex flex-col gap-8`}>
        <div>
          <div className="flex flex-col items-center justify-center gap-4 md:gap-16 overflow-hidden">
            <HeaderSectionNew />
            <div className="flex justify-center px-4 w-full">
              <div className="w-full max-w-6xl mb-24 flex flex-col gap-8">
                <div className="grid grid-cols-1  lg:grid-cols-4 gap-4">
                  {/** Pie Chart section--------------------------------------------------------------------------------------- */}
                  {!transactions || transactions.length == 0 ? null : (
                    <div className="col-span-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="col-span-1 md:col-span-1">
                        <div className="border rounded-xl p-4 grid gap-2">
                          <h2 className="text-lg font-medium">
                            Portfolio nach Zielinvestments
                          </h2>

                          <DonutChart
                            minimalistic
                            style={{ textAlign: "left" }}
                            headline=""
                            series={
                              portfolioSeries.series
                                ? portfolioSeries.series
                                : []
                            }
                            labels={wallet!.positions.map(
                              (position) => position.name
                            )}
                            theme={theme}
                          />
                        </div>
                      </div>
                      <div className="col-span-1 md:col-span-1">
                        <div className="border rounded-xl p-4 grid gap-2">
                          <h2 className="text-lg font-medium">
                            Portfolio nach Investitionsphase{" "}
                          </h2>

                          <DonutChart
                            minimalistic
                            style={{ textAlign: "left" }}
                            headline=""
                            series={
                              stageSeries.series ? stageSeries.series : []
                            }
                            labels={wallet!.positionsByStage.map(
                              (position) => position.stage
                            )}
                            theme={theme}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/** Wallet section---------------------------------------------------------------------------------------- */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="w-full  rounded-xl">
                    <div className="w-full h-8  flex gap-8">
                      <p
                        onClick={() => setActiveSection(0)}
                        className={`cursor-pointer ${
                          transactions_active
                            ? "border-b-2 border-b-gray-400"
                            : ""
                        }`}
                      >
                        Transaktionen
                      </p>{" "}
                      <p
                        onClick={() => setActiveSection(1)}
                        className={`cursor-pointer ${
                          positions_active ? "border-b-2 border-b-gray-400" : ""
                        }`}
                      >
                        Positionen
                      </p>
                    </div>
                    {positions_active ? (
                      <div className="mt-8 overflow-hidden">
                        <div className="w-full px-2 border-t border-b border-gray-400">
                          <div className=" py-3">
                            <div className="header grid grid-cols-3">
                              <div className="">
                                <p className="text-sm font-medium">Produkt</p>
                              </div>
                              <div className="">
                                <p className="text-sm font-medium hidden md:block">
                                  Zielinvestment
                                </p>
                              </div>

                              <div className=" ">
                                <p className="text-sm text-right font-medium">
                                  Gesamtvolumen
                                </p>
                                <Typography
                                  sx={{ display: { xs: "block", md: "none" } }}
                                  variant="h6"
                                  style={{ textAlign: "right" }}
                                >
                                  <b></b>
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul>
                          {vcplatform_funds
                            ? wallet?.positions.map((position, i) => (
                                <WalletPositionV2
                                  key={i}
                                  position={position}
                                  fund={vcplatform_funds.find(
                                    (fund) =>
                                      fund.fundId ==
                                      position.fund?.targetInvestmentId
                                  )}
                                  style={{ marginBottom: "20px" }}
                                />
                              ))
                            : null}
                        </ul>
                      </div>
                    ) : null}

                    {transactions_active ? (
                      <div className=" mt-8">
                        <InvestmentDetailsDialog />{" "}
                        {/** Transaction section---------------------------------------------------------------------------------------- */}
                        <div className="w-full px-2 border-gray-400 rounded-t-lg">
                          <div className="  py-3">
                            <div className="header grid grid-cols-3 md:grid-cols-5">
                              <p className="text-sm font-semibold ">Datum</p>
                              <p className="text-sm font-semibold hidden md:block">
                                Typ
                              </p>
                              <p className="text-sm font-semibold ">Produkt</p>
                              <p className="text-sm font-semibold ">Status</p>
                              <p className="text-sm font-semibold text-right hidden md:block">
                                Betrag
                              </p>
                            </div>{" "}
                          </div>
                        </div>
                        <ul className="shadow-sm rounded-lg">
                          {transactions.map((transaction, i) => {
                            const is_coinvest =
                              transaction.type == INVESTMENT_TYPE.COINVEST;
                            return (
                              <li
                                key={i}
                                className="odd:bg-[#E2EAF2] hover:bg-[#D0D7DE] cursor-pointer  border-gray-400 last:rounded-b-lg first:rounded-t-lg"
                              >
                                {" "}
                                {/* <Link
                                to={
                                  is_coinvest
                                    ? "#"
                                    : `/direct/investment-details?id=${transaction.id}`
                                }
                              > */}
                                <div
                                  className="w-full bg-odd px-2 "
                                  onClick={() => {
                                    setActiveInvestmentId(transaction.id);
                                  }}
                                >
                                  <div className="header grid grid-cols-3 md:grid-cols-5 py-3">
                                    <p className="text-sm font-semibold flex items-center">
                                      {transaction.signedAt != null
                                        ? new Date(
                                            transaction.signedAt
                                          ).toLocaleDateString("de-de")
                                        : transaction.offerCreatedAt != null
                                        ? new Date(
                                            transaction.offerCreatedAt
                                          ).toLocaleDateString("de-de")
                                        : "-"}
                                    </p>
                                    <p className="text-sm font-semibold items-center  hidden md:flex">
                                      {is_coinvest
                                        ? "Co-Investment"
                                        : "Investment"}
                                    </p>
                                    <p className="text-sm font-semibold  flex items-center">
                                      {transaction.fundName}
                                    </p>
                                    <p className="text-sm font-semibold  flex items-center">
                                      {transaction.state ? (
                                        <InvestmentStatusChipV2
                                          state={transaction.state}
                                        />
                                      ) : null}
                                      {is_coinvest ? (
                                        <InvestmentStatusChipV2
                                          state={INVESTMENT_STATE.DELIVERED}
                                        />
                                      ) : null}
                                    </p>
                                    <p className="text-sm font-semibold text-right justify-end items-center hidden md:flex">
                                      {transaction.amount.toLocaleString(
                                        "de-de"
                                      )}
                                      €
                                    </p>
                                  </div>
                                </div>{" "}
                                {/* </Link> */}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : //   <TransactionList
                    //     transactions={transactions.map((transaction) => {
                    //       const isCoInvest =
                    //         transaction.type == INVESTMENT_TYPE.COINVEST;
                    //       return {
                    //         transaction_url: isCoInvest
                    //           ? undefined
                    //           : `/portal/investment?id=${transaction.id}`,
                    //         theme: theme,
                    //         target: transaction.fundName,
                    //         type: isCoInvest ? 1 : 0,
                    //         date: transaction.signedAt.toDateString(),
                    //         amount: transaction.amount,
                    //         documents: transaction.documents?.map((doc) => {
                    //           console.log(doc);
                    //           return {
                    //             name: doc.fileName,
                    //             url: doc.url,
                    //           };
                    //         }),
                    //       };
                    //     })}
                    //     theme={theme}
                    //   />

                    null}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                    textAlign: "center",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <InvestmentDetailsDrawer
          investment_id={active_investment_id}
          dashboard={dashboard}
          setInvestmentId={setActiveInvestmentId}
        />
      </div>
    );
  };

  return (
    <>
      {/** Aktion erforderlich secton---------------------------------------------------------------------------------------- */}

      {wallet ? <WalletSection /> : null}
    </>
  );
}

type Stage = {};

export type WalletPositionType = {
  productId: string;
  name: string;
  amount: number;
  stage: string;
  fund: Fund | undefined;
  investments: TransactionType[];
};

type StagePosition = {
  stage: string;
  amount: number;
};

class Wallet {
  stageMap = new Map();

  positions: WalletPositionType[] = [];
  positionsByStage: StagePosition[] = [];
  funds: Fund[] = [];

  constructor(transaction: TransactionType[], funds: Fund[]) {
    console.log(funds);
    this.funds = funds.filter((fund) =>
      transaction.find((transaction) => transaction.fundId == fund.cashlinkId)
    );

    //create mapping for stages
    funds.forEach((fund) => {
      var stage = fund.investmentStage;
      this.stageMap.set(
        fund.cashlinkId,
        FUND_STAGE[stage as string as keyof typeof FUND_STAGE]
      );
    });

    //seed with investments
    transaction.forEach((transaction) => {
      this.putInvestment(transaction);
      this.putInvestmentStage(transaction);
    });

    this.positions.sort((a, b) => b.amount - a.amount);
    this.positionsByStage.sort((a, b) => b.amount - a.amount);
  }

  getFund(fundId: string): Fund | undefined {
    return this.funds.find((fund) => fund.cashlinkId == fundId);
  }

  putInvestment(investment: TransactionType) {
    for (var i = 0; i < this.positions.length; i++) {
      if (this.positions[i].productId == investment.fundId) {
        this.positions[i].name = investment.fundName;
        this.positions[i].investments.push(investment);
        this.positions[i].amount += investment.amount;
        this.positions[i].name = investment.fundName;
        return;
      }
    }

    this.positions.push({
      productId: investment.fundId,
      name: investment.fundName,
      amount: investment.amount,
      investments: [investment],
      stage: this.stageMap.get(investment.fundId),
      fund: this.getFund(investment.fundId),
    });
  }

  putInvestmentStage(investment: TransactionType) {
    var fund = this.getFund(investment.fundId);
    var investmentStage = fund?.investmentStage + "";
    switch (investmentStage) {
      case "EARLY_AND_GROWTH":
        investmentStage = "Früh- und Wachstumsphase";
        break;
      case "SEED":
        investmentStage = "Frühphase";
        break;
      case "GROWTH":
        investmentStage = "Wachstumsphase";
    }

    //update the entry for the specific stage
    for (var i = 0; i < this.positionsByStage.length; i++) {
      if (this.positionsByStage[i].stage == investmentStage) {
        this.positionsByStage[i].amount += investment.amount;
        this.positionsByStage[i].stage = investmentStage;
        return;
      }
    }

    this.positionsByStage.push({
      stage: investmentStage,
      amount: investment.amount,
    });
  }
}
