import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrimaryButton, {
  PrimaryButtonV2,
  SecondaryButtonV2,
} from "../../components/input-elements/buttons";
import LoadingScreen from "../../components/loading-screen";
import Drawer from "../../template/drawer";
import FooterV2 from "../../template/footer-v2";
import MobileBottomNavigation, {
  MobileBottomNavigationV2,
} from "../../template/mobile-bottom-navigation";
import { Fund } from "../../types/fund_types";
import {
  MatchingDTO,
  UserMatchingDTO,
  USER_STATE,
} from "../../types/generic_types";
import { InventureUser } from "../../types/inventure-user_types";
import { News } from "../../types/vc-platform/news_types";
import API_CLIENT from "../../utility/api-client";
import BankAndTax from "./bank-and-tax/bank-and-tax";

import { Dashboard } from "./dashboard/dashboard";
import InvestmentDetails from "./investment-details/investment-details";
import InvestmentProcessFull from "./investment-process-full/investment-process-full";
import NotLoggedIn from "./not-logged-in";
import Profile from "./profile/profile";
import Guide from "./investment-process-full/subpages/guide";
import Security from "./security/security";
import LegalPerson from "./legal-person/legal-person";
import ForceEmailVerification, {
  ForceEmailVerificationV2,
} from "../signup/force-email-verification";
import OnboardingZero, {
  OnboardingZeroV2,
} from "../portal/onboarding/onboarding-zero";
import { ro } from "date-fns/locale";
import InvestmentProcessLegalPerson from "./investment-process-legal-person/investment-process-legal-person";

interface AppContextProps {
  authenticated: boolean;
  token: string;
  userEmail: string;
  sub: string;
  user: null | InventureUser;
  matchingData: null | UserMatchingDTO;
  userState: USER_STATE | undefined;
  setUserState: (state: USER_STATE) => void;
  updateUser: (user: InventureUser) => void;
  loading: boolean;
  funds: Fund[];
  userLoading: boolean;
}

export const AppContext = React.createContext<AppContextProps>({
  updateUser: (user) => null,

  authenticated: true,
  token: "",
  userEmail: "",
  sub: "",
  user: null,
  matchingData: null,
  userState: USER_STATE.NONE,
  userLoading: true,
  setUserState: (state) => null,
  loading: true,
  funds: [],
});

export default function DirectRouter() {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    user,
    isLoading,
    loginWithRedirect,
  } = useAuth0();
  const [access_token, setAccessToken] = useState("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [sub, setSub] = useState<string>("");
  const [ivUser, setIvUser] = useState<InventureUser | null>(null);
  const [userLoading, setUserLoading] = useState(true);
  const [userEmailVerified, setUserEmailVerified] = useState<boolean>(false);
  const [userState, setUserState] = useState<USER_STATE | undefined>();
  const [loading, setLoading] = useState(true);
  const [drawer_open, setDrawerOpen] = useState(true);
  const [funds, setFunds] = useState<Fund[]>([]);

  const activateHubspot = (email: string | undefined) => {
    var _hsq = ((window as any)._hsq = (window as any)._hsq || []);
    _hsq.push([
      "identify",
      {
        email: email,
      },
    ]);
    _hsq.push(["setPath", "/"]);
    _hsq.push(["trackPageView"]);
    // window.hsConversationsSettings = {
    //   identificationEmail: email,
    // };
    //window.HubSpotConversations.widget.load();
  };

  useEffect(() => {
    const el = document.querySelector(".loader-container");
    if (el) {
      el.remove();
    }
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({});

        var emailVerified = user?.email_verified;
        var email = user?.email;
        var sub = user?.sub;
        activateHubspot(email);
        if (emailVerified != undefined && email && user && sub) {
          API_CLIENT.getUser(
            accessToken,
            (user) => {
              setIvUser(user);
              setUserLoading(false);
            },
            (error) => console.log(error)
          );
          API_CLIENT.getUserState(
            accessToken,
            (state: USER_STATE) => {
              setUserState(state);
            },
            (error: string) => {
              console.log(error);
            }
          );
          API_CLIENT.getFunds(
            accessToken,
            (funds) => {
              setFunds(funds);
            },
            (error) => {
              console.log(error);
            }
          );

          setAccessToken(accessToken);

          setUserEmailVerified(emailVerified);
          setSub(sub);
          setUserEmail(email);

          setInterval(() => {
            if (userState == USER_STATE.ONBOARDED_2) return;
            API_CLIENT.getUserState(
              accessToken,
              (state: USER_STATE) => {
                console.log("state");
                setUserState(state);
              },
              (error: string) => {
                console.log(error);
              }
            );
          }, 20000);
        }
      } catch (e) {
        setUserState(USER_STATE.NONE);
        setUserLoading(false);
      }
    };
    getUserMetadata();
  }, [user]);

  useEffect(() => {
    if (userState && ivUser) {
      setLoading(false);
    }
  }, [userState, ivUser]);

  var matchingData: MatchingDTO = {
    event: "",
    eventId: undefined,
    sourceUrl: undefined,
    em: user?.email,
    ph: ivUser?.natural_person?.phone,
    fn: ivUser?.natural_person?.forename,
    ln: ivUser?.natural_person?.surname,
  };

  if (userLoading || isLoading || (!isLoading && loading && isAuthenticated)) {
    return <LoadingScreen className="h-screen" />;
  }

  var redirect_target = localStorage.getItem("redirect-target");
  if (userEmailVerified && isAuthenticated && redirect_target) {
    localStorage.removeItem("redirect-target");
    return <Redirect to={redirect_target} />;
  }

  return (
    <AppContext.Provider
      value={{
        updateUser: (user) => setIvUser(user),
        token: access_token,
        authenticated: isAuthenticated,
        userEmail: userEmail,
        sub: sub,
        user: ivUser,
        matchingData: matchingData,
        userState: userState,
        userLoading: userLoading,
        setUserState: (state) => {
          console.log(state);
          setUserState(state);
        },
        loading: loading,
        funds: funds,
      }}
    >
      {userLoading ? null : (
        <div>
          <div className="bg-[#F2F7FD]">
            <div className="flex w-full">
              <Drawer
                className="hidden md:block"
                isOpen={drawer_open}
                setIsOpen={(open) => setDrawerOpen(open)}
              ></Drawer>
              <div className="md:hidden">
                <MobileBottomNavigationV2 />
              </div>
              <div className="w-full min-h-screen">
                <div className="py-4 px-6  items-center hidden sm:flex">
                  <img
                    className="w-32"
                    src={process.env.REACT_APP_LOGO_LIGHT}
                  />
                  {/* {!isAuthenticated ? (
                <div className="ml-8 flex gap-4">
                  <PrimaryButtonV2
                    onClick={() =>
                      loginWithRedirect({
                        screen_hint: "signup",
                        appState: {
                          returnTo: `${window.location.pathname}${window.location.search}`,
                        },
                      })
                    }
                  >
                    Account erstellen
                  </PrimaryButtonV2>
                  <SecondaryButtonV2
                    onClick={() =>
                      loginWithRedirect({
                        appState: {
                          returnTo: `${window.location.pathname}${window.location.search}`,
                        },
                      })
                    }
                  >
                    Login
                  </SecondaryButtonV2>
                </div>
              ) : null} */}
                  <div className="flex-grow"></div>
                  <div className="flex items-center gap-4"></div>
                </div>
                {!isLoading &&
                !isAuthenticated &&
                window.location.pathname != "/direct/investment" &&
                window.location.pathname != "/direct/start-investment" ? (
                  <NotLoggedIn />
                ) : !userEmailVerified && isAuthenticated ? (
                  <ForceEmailVerificationV2 />
                ) : (
                  <div className=" flex items-center flex-col">
                    <div className="w-full">
                      <Switch>
                        <Route
                          path="/direct/investment/legal"
                          component={InvestmentProcessLegalPerson}
                        ></Route>
                        <Route
                          path="/direct/investment"
                          component={InvestmentProcessFull}
                        ></Route>

                        <Route
                          path="/direct/start-investment"
                          component={Guide}
                        ></Route>
                        <Route
                          path="/direct/profile"
                          component={Profile}
                        ></Route>
                        <Route
                          path="/direct/dashboard"
                          component={Dashboard}
                        ></Route>
                        <Route
                          path="/direct/bank-and-tax"
                          component={BankAndTax}
                        ></Route>
                        <Route
                          path="/direct/legal-person"
                          component={LegalPerson}
                        ></Route>
                        <Route
                          path="/direct/security"
                          component={Security}
                        ></Route>
                        <Route
                          path="/direct/investment-details"
                          component={InvestmentDetails}
                        ></Route>
                      </Switch>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/** Hide Footer for specific paths */}
            {/* {window.location.pathname != "/portal/onboardingg" ? (
          <FooterV2 />
        ) : null} */}
          </div>
        </div>
      )}
    </AppContext.Provider>
  );
}
