import { useAuth0 } from "@auth0/auth0-react";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PdfLinkOverlay from "../../../../components/data-display/pdf-link-overlay";
import HookFormTextField, {
  HookFormCompanyTypeSelectV2,
  HookFormCountrySelect,
  HookFormCountrySelectV2,
  HookFormDatePicker,
  HookFormDatePickerV2,
  HookFormEducationPicker,
  HookFormEducationPickerV2,
  HookFormFile,
  HookFormFileV2,
  HookFormLegitimationDocumentPicker,
  HookFormLegitimationDocumentPickerV2,
  HookFormProfessionPicker,
  HookFormProfessionPickerV2,
  HookFormSalutationSelect,
  HookFormSalutationSelectV2,
  HookFormSwitch,
  HookFormSwitchV2,
  HookFormTextFieldV2,
  HookFormTitleSelect,
  HookFormTitleSelectV2,
} from "../../../../components/hook-form-inputs";
import PrimaryButton, {
  PrimaryButtonV2,
  SecondaryButton,
  SecondaryButtonV2,
} from "../../../../components/input-elements/buttons";
import LoadingScreen from "../../../../components/loading-screen";
import { USER_STATE } from "../../../../types/generic_types";
import {
  CreateInvestmentDTO,
  EDUCATION,
  Investment,
  PROFESSION,
  SelfDisclosure,
} from "../../../../types/investment_types";
import {
  DOCUMENT_TYPE,
  INVESTOR_TYPE,
  OnboardingData,
  OnboardingZero,
} from "../../../../types/onboarding_types";
import API_CLIENT from "../../../../utility/api-client";
import { AppContext } from "../../direct-router";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import MultistepFormButtons, {
  MultistepFormButtonsV2,
} from "../../../../components/vertical-multistep-form/multistep-form-buttons";
import IvSwitch from "../../../../components/input-elements/iv-switch";
import {
  COUNTRY,
  InventureUser,
  LegalPerson,
  LEGAL_ENTITY_TYPE,
} from "../../../../types/inventure-user_types";
import { InvestmentContextFull } from "../investment-process-full";
import BenefitingPersonManager, {
  BenefitingPersonManagerV2,
} from "../../../../components/data-display/benefiting-persons";
import { Link, useHistory } from "react-router-dom";

type PersonalDataProps = {
  back: () => void;
  next: () => void;
};

export function PersonalData({ back, next }: PersonalDataProps) {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [legal_person, setLegalPerson] = useState(false);
  const app_context = useContext(AppContext);
  const investment_context = useContext(InvestmentContextFull);
  const user_state = app_context.userState;
  const user_type = app_context.user?.investor_type;
  const user = app_context.user;
  const history = useHistory();

  useEffect(() => {
    if (user_state) setLoading(false);
  }, [user_state]);

  if (loading) {
    return <LoadingScreen />;
  }

  const OnboardedStep = () => (
    <div className="grid">
      <div className="grid gap-4">
        <form
          onSubmit={(e: any) => {
            e.preventDefault();
            investment_context.nextStep();
          }}
          className="flex flex-col items-start gap-8"
        >
          <div>
            <p className="text-xs">Stammdaten</p>
            <p className="text-primary_light">
              Du hast deine Daten bereits hinterlegt und kannst mit mit der
              Investition fortfahren. Falls du Änderungen wünschst, findest du
              deine Kundendaten unter dem Menüpunkt “Profil”.
            </p>
          </div>

          <MultistepFormButtonsV2
            back={true}
            backFunction={() => back()}
            disabled={false}
          />
        </form>
      </div>
    </div>
  );

  if (loading && !app_context.user) {
    return <LoadingScreen className="h-screen" />;
  }

  const is_onboarded_1 = user_state == USER_STATE.ONBOARDED_1;
  const is_onboarded_2 = user_state == USER_STATE.ONBOARDED_2;
  const is_legal = user_type == INVESTOR_TYPE.LEGAL_PERSON;
  const has_legal_person = app_context.user?.legal_person != null;
  const has_benefiting_person = user?.benefiting_persons
    ? user.benefiting_persons.length > 0
    : false;

  const user_state_zero = user_state == USER_STATE.ZERO;
  const user_state_nat = user_state == USER_STATE.NONE;
  const user_state_legal =
    (is_onboarded_1 && is_legal && !has_legal_person) ||
    (is_onboarded_2 && is_legal && !has_legal_person);
  const user_state_benefiting =
    (is_onboarded_1 &&
      is_legal &&
      has_legal_person &&
      !has_benefiting_person) ||
    (is_onboarded_2 && is_legal && has_legal_person && !has_benefiting_person);

  const user_state_onboarded =
    user_state == USER_STATE.ONBOARDED_1 ||
    user_state == USER_STATE.ONBOARDED_2;
  const investment_id = investment_context.investment?.id;

  if (legal_person && investment_id) {
    const LegalInvestmentDialog = () => {
      return (
        <div className="grid gap-16">
          <div>
            <h2>Zeichnung als juristische Person</h2>
            <p className="text-lg">
              Du kannst hier eine Investition über eine jurisitsche Person
              starten.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <div className="bg-slate-200 rounded-xl p-4 grid gap-2">
              <h3 className="font-semibold">1. Beitrittserklärung anfordern</h3>
              <p>
                Du kannst die Beitrittserklärung für juristische Personen bequem
                über unsere Onlineplattform im nächsten Schritt anfordern.
              </p>
            </div>
            <div className="bg-slate-200 rounded-xl p-4 grid gap-2">
              <h3 className="font-semibold">
                2. Beitrittserklärung online oder offline ausfüllen
              </h3>
              <p>
                Im nächsten Schritt muss die Beitrittserklärung ausgefüllt
                werden. Das kann entweder über unseren Partner YouSign erfolgen
                oder händisch über das Ausfüllen der Beitrittserklärung als PDF
                oder ausgedruckt.
              </p>
            </div>
            <div className="bg-slate-200 rounded-xl p-4 grid gap-2">
              <h3 className="font-semibold">
                3. Hochladen der Beitrittserklärung direkt auf der Plattform
                (Falls offline ausgefüllt)
              </h3>
              <p>
                Im nächsten Schritt muss die Beitrittserklärung ausgefüllt
                werden.
              </p>
            </div>
            <div className="bg-slate-200 rounded-xl p-4 grid gap-2">
              <h3 className="font-semibold">
                4. Unterschreiben der Beitrittserklärung
              </h3>
              <p>
                Über unseren Partner YouSign kannst du die Beitrittserklärung
                direkt unterschreiben.
              </p>
            </div>
            <div className="bg-slate-200 rounded-xl p-4 grid gap-2">
              <h3 className="font-semibold">Fertig</h3>
              <p>
                Falls du hilfe benötigst wende dich gerne an unseren
                Kundenservice.
              </p>
            </div>
          </div>
          <div className="flex gap-4">
            <SecondaryButtonV2
              onClick={() => {
                setLegalPerson(false);
              }}
            >
              Zurück
            </SecondaryButtonV2>
            <PrimaryButtonV2
              onClick={() =>
                API_CLIENT.makeBindingOfferV3Legal(
                  app_context.token,
                  investment_id,
                  (investment: Investment) => {
                    (window as any).dataLayer.push({
                      event: "investment-accepted",
                      user_id: app_context.sub,
                      "investment-amount": investment.amount,
                    });
                    investment_context.setInvestment(investment);
                    history.push(
                      "/direct/dashboard?investment_id=" + investment.id
                    );

                    localStorage.setItem(
                      `investment-amount-${investment.campaignId}`,
                      ""
                    );
                  },
                  (error: string) => {
                    console.log(error);
                  },
                  ""
                )
              }
            >
              Investition starten
            </PrimaryButtonV2>
          </div>
        </div>
      );
    };

    return <LegalInvestmentDialog />;
  }

  return (
    <div className="grid gap-8">
      <p className="text-3xl font-medium font-headline text-primary">
        Gib nun Informationen zu deiner Person an
      </p>
      {isAuthenticated}

      {isAuthenticated ? (
        <div className="grid gap-8">
          {user_state_zero ? (
            <div>
              <PersonalDataZero />
            </div>
          ) : user_state_nat ? (
            <PersonalDataTwo />
          ) : user_state_legal ? (
            <PersonalDataLegalPerson />
          ) : user_state_benefiting ? (
            <PersonalDataBenefitingPerson />
          ) : user_state_onboarded ? (
            <OnboardedStep />
          ) : null}{" "}
          <div className="rounded-lg flex flex-col items-start gap-2 bg-primary bg-opacity-5 p-4">
            <p>
              Du möchtest über eine juristische Person (GmbH, UG etc.)
              investieren?{" "}
            </p>

            <a
              href="https://meetings.hubspot.com/dat-le"
              target="_blank"
              className="h-10 eading-none flex gap-2 items-center border px-4 rounded-full border-primary text-sm hover:bg-slate-100"
            >
              Buche dir einen Termin
              <img
                src="https://img.icons8.com/?size=512&id=7789&format=png"
                className="h-6"
              />
            </a>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-4 p-4 rounded-xl bg-primary bg-opacity-5 text-primary">
          <p className="text-base">
            Logge dich ein oder erstelle dir einen Account, um die Investition
            fortzusetzen.
          </p>
          <div className="flex gap-4 flex-wrap">
            <PrimaryButtonV2
              onClick={() => {
                localStorage.setItem(
                  "redirect-target",
                  `/direct/investment${window.location.search}`
                );
                loginWithRedirect({
                  screen_hint: "signup",
                  appState: {
                    returnTo: `${window.location.pathname}${window.location.search}`,
                  },
                });
              }}
            >
              Account erstellen
            </PrimaryButtonV2>
            <SecondaryButtonV2
              onClick={() => {
                loginWithRedirect({
                  appState: {
                    returnTo: `${window.location.pathname}${window.location.search}`,
                  },
                });
              }}
            >
              Einloggen
            </SecondaryButtonV2>
          </div>
        </div>
      )}
    </div>
  );
}

function PersonalDataZero() {
  const app_context = useContext(AppContext);
  const investment_context = useContext(InvestmentContextFull);
  const {
    register,
    control,
    formState,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm<any>({ mode: "onChange" });

  function submit(data: OnboardingZero) {
    investment_context.setLoading(true);
    var affiliate_program = "";
    var cookie = Cookies.get("iv-affiliate");
    if (cookie != undefined) {
      affiliate_program = cookie;
    }
    data.affiliate_program = affiliate_program;
    //setLoading(true);
    API_CLIENT.putOnboardingZero(
      app_context.token,
      data,
      (user) => {
        app_context.setUserState(USER_STATE.NONE);
        app_context.updateUser(user);
        //setLoading(false);
      },
      () => {
        //setLoading(false);
        console.log("error");
      }
    );
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" className="font-semibold">
            Stammdaten
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <HookFormSalutationSelectV2
            control={control}
            name={"salutation"}
            error={false}
            label={"Anrede*"}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <HookFormTitleSelectV2
            control={control}
            name={"title"}
            error={false}
            label={"Titel"}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <HookFormTextFieldV2
            required
            control={control}
            name={"firstName"}
            error={errors.firstname != null}
            label={"Vorname*"}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <HookFormTextFieldV2
            required
            control={control}
            name={"lastName"}
            error={errors.surname != null}
            label={"Nachname*"}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <HookFormTextFieldV2
            control={control}
            defaultValue={""}
            name={"phoneNumber"}
            error={false}
            label={"Telefonnummer*"}
            required
          />
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <Controller
            name="termsAccepted"
            control={control}
            rules={{
              required: true,
            }}
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                style={{ marginTop: "20px", textAlign: "left" }}
                control={
                  <Checkbox
                    style={{ marginRight: "30px" }}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                  />
                }
                label={
                  <Typography variant="body2" style={{ fontSize: "12px" }}>
                    Die inVenture Capital iVC GmbH wird bei der Vermittlung von
                    Finanzinstrumenten ausschließlich im Namen, auf Rechnung und
                    unter der Haftung des Wertpapierinstituts Effecta GmbH
                    tätig. Ich habe die{" "}
                    <PdfLinkOverlay
                      title="Effecta AGB"
                      pdf="https://inventurestorage.blob.core.windows.net/platform/effecta_terms_of_service.pdf"
                    >
                      AGB
                    </PdfLinkOverlay>{" "}
                    und die{" "}
                    <PdfLinkOverlay
                      title="Effecta Kundeninformation"
                      pdf="https://inventurestorage.blob.core.windows.net/platform/kundeninformationen_effecta.pdf"
                    >
                      Kundeninformationen
                    </PdfLinkOverlay>{" "}
                    der Effecta GmbH gelesen und nehme diese zur Kenntnis.*
                  </Typography>
                }
              />
            )}
          />
        </Grid> */}

        <Grid item xs={12} md={6} />
        <Grid item xs={12} className="flex">
          <MultistepFormButtonsV2
            disabled={!formState.isValid}
            back={false}
            backFunction={function (): void {
              throw new Error("Function not implemented.");
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
}

function PersonalDataTwo() {
  const investment_context = useContext(InvestmentContextFull);
  const app_context = useContext(AppContext);
  const {
    register: register1,
    control: control1,
    formState: formState1,
    formState: { errors: errors1, isValid: isValid1 },
    handleSubmit: handleSubmit1,
    setValue: setValue1,
  } = useForm<any>({ mode: "onChange" });

  interface PersonData {
    is_legal_person: boolean;
    investor_type?: INVESTOR_TYPE;
    occupation?: PROFESSION;
    educational_qualification?: EDUCATION;
    birthdate: Date;
    birthplace: string;
    citizenship: string;
    birthcountry: string;
    country: string;
    street: string;
    address_addition: string;
    zip: string;
    city: string;
    pep_status: boolean;
    is_beneficiary: boolean;
    document_id: string;
    document_valid_from: Date;
    document_valid_to: Date;
    document_type: DOCUMENT_TYPE;
    document_issuer: string;
  }

  const submitStep = (data: PersonData) => {
    var campaign = investment_context.campaign;
    var amount = investment_context.amount;

    if (campaign == null || amount == null) {
      return;
    }
    investment_context.setLoading(true);
    let o_data = {
      investor_type: data.is_legal_person
        ? INVESTOR_TYPE.LEGAL_PERSON
        : INVESTOR_TYPE.NATURAL_PERSON,
      document_type: data.document_type,
      document_issuer: data.document_issuer,
      document_valid_from: data.document_valid_from.toString(),
      document_valid_to: data.document_valid_to.toString(),
      birthdate: data.birthdate.toString(),
      birthplace: data.birthplace,
      birthcountry: data.birthcountry,
      citizenship: data.citizenship,
      street: data.street,
      city: data.city,
      zip: data.zip,
      country: data.country,
      pep_status: data.pep_status,
      is_beneficiary: data.is_beneficiary,
      occupation: data.occupation,
      educational_qualification: data.educational_qualification,
      address_addition: data.address_addition,
      document_id: data.document_id,
    } as OnboardingData;
    API_CLIENT.putOnboarding(
      app_context.token,
      o_data,
      (user: InventureUser) => {
        app_context.updateUser(user);
        app_context.setUserState(USER_STATE.ONBOARDED_1);
        (window as any).dataLayer.push({
          event: "onboarding-form-sent",
        });

        // setLoading(false);
      },
      (error) => {
        console.log(error);
        // setLoading(false);
      }
    );
  };

  return (
    <form onSubmit={handleSubmit1(submitStep)}>
      <div className="grid grid-cols-1 gap-8">
        <div className="col-span-2">
          <Divider />
        </div>
        <div className="grid cols-2 gap-4">
          <div className="col-span-2 flex items-center gap-2">
            <div className="w-6 h-6 flex items-end">
              <img src="https://ik.imagekit.io/inventure/App/Icons/icons8-earth-globe-96_QjZMjJt7o.png?updatedAt=1719384817135" />
            </div>
            <p className="text-xl font-semibold text-primary">Hintergrund</p>
          </div>
          <div>
            <HookFormProfessionPickerV2
              name="occupation"
              defaultValue={PROFESSION.NO_ANSWER}
              error={false}
              control={control1}
              label="Beruf*"
            ></HookFormProfessionPickerV2>
          </div>
          <div>
            <HookFormEducationPickerV2
              name="educational_qualification"
              defaultValue={EDUCATION.NO_ANSWER}
              error={false}
              control={control1}
              label="Bildungsabschluss*"
            ></HookFormEducationPickerV2>
          </div>
          <div>
            <HookFormCountrySelectV2
              name="citizenship"
              defaultValue={"DEU"}
              error={false}
              control={control1}
              label="Staatsbürgerschaft*"
            ></HookFormCountrySelectV2>
          </div>
          <div>
            <HookFormDatePickerV2
              name="birthdate"
              control={control1}
              label="Geburtsdatum*"
              defaultValue={""}
              error={errors1.birthdate != null}
              required
            />
          </div>
          <div>
            <HookFormTextFieldV2
              name="birthplace"
              control={control1}
              label="Geburtsort*"
              required
              defaultValue={""}
              error={errors1.birthplace != null}
            />
          </div>
          <div>
            <HookFormCountrySelectV2
              name="birthcountry"
              defaultValue={"DEU"}
              error={false}
              control={control1}
              label="Geburtsland*"
            ></HookFormCountrySelectV2>
          </div>
        </div>
        <div className="col-span-2">
          <Divider />
        </div>
        <div className="grid cols-2 gap-4">
          <div className="col-span-2 flex items-center gap-2">
            <div className="w-6 h-6 flex items-end">
              <img src="https://ik.imagekit.io/inventure/App/Icons/icons8-home-96_0HqZZPoin.png?updatedAt=1719384817070" />
            </div>
            <p className="text-xl font-semibold text-primary">Adresse</p>
          </div>
          <div className="col-span-2">
            <HookFormCountrySelectV2
              name="country"
              defaultValue={"DEU"}
              error={false}
              control={control1}
              label="Land*"
            ></HookFormCountrySelectV2>
          </div>
          <div>
            <HookFormTextFieldV2
              required
              name="street"
              control={control1}
              label="Straße und Hausnummer*"
              defaultValue={null}
              error={errors1.street != null}
            />
          </div>
          <div>
            <HookFormTextFieldV2
              name="address_addition"
              control={control1}
              label="Adresszusatz"
              defaultValue={null}
              error={false}
            />
          </div>
          <div>
            <HookFormTextFieldV2
              name="zip"
              control={control1}
              label="Postleitzahl*"
              required
              defaultValue={null}
              error={errors1.zip != null}
            />
          </div>
          <div>
            <HookFormTextFieldV2
              name="city"
              required
              control={control1}
              label="Ort*"
              defaultValue={null}
              error={errors1.city != null}
            />
          </div>
        </div>

        <div className="col-span-2">
          <Divider />
        </div>
        <div className="grid cols-2 gap-4">
          <div className="col-span-2 flex items-center gap-2">
            <div className="w-6 h-6 flex items-end">
              <img src="https://ik.imagekit.io/inventure/App/Icons/icons8-id-96_gQJwD7bM0.png?updatedAt=1719384817095" />
            </div>
            <p className="text-xl font-semibold text-primary">
              Legitimationsdokument
            </p>
          </div>
          <div>
            <HookFormLegitimationDocumentPickerV2
              name="document_type"
              defaultValue={DOCUMENT_TYPE.IDCARD}
              error={false}
              control={control1}
              label="Land*"
            ></HookFormLegitimationDocumentPickerV2>
          </div>
          <div>
            <HookFormTextFieldV2
              name="document_id"
              control={control1}
              label="Ausweisnummer*"
              defaultValue={null}
              required
              error={errors1.document_id != null}
            />
          </div>
          <div>
            <HookFormTextFieldV2
              name="document_issuer"
              control={control1}
              label="Ausstellende Behörde*"
              defaultValue={null}
              required
              error={errors1.document_issuer != null}
            />
          </div>
          <div />
          <div>
            <HookFormDatePickerV2
              name="document_valid_from"
              control={control1}
              label="Ausgestellt am*"
              defaultValue={null}
              error={errors1.document_valid_from != null}
            />
          </div>
          <div>
            <HookFormDatePickerV2
              name="document_valid_to"
              control={control1}
              label="Gültig bis*"
              defaultValue={null}
              error={errors1.document_valid_to != null}
            />
          </div>
        </div>
        <div className="col-span-2 grid gap-2 text-slate-500">
          <Divider />
          <p className="text-sm">*Pflichtfeld</p>
        </div>
        {/* <div className="rid grid cols-1 gap-4">
          <div className="col-span-2 flex items-center gap-2">
            <div className="w-6 h-6 flex items-end">
              <img src="https://ik.imagekit.io/inventure/App/Icons/icons8-new-company-96_zOSBNIF8M.png?updatedAt=1719384817104" />
            </div>
            <p className="text-xl font-semibold text-primary">
              Investmentvehikel
            </p>
          </div>
          <div>
            <HookFormSwitchV2
              name="is_legal_person"
              control={control1}
              label="Möchtest du über ein Vehikel (GmbH/UG) investieren? Du kannst im nächsten Schritt die dazugehörigen Daten angeben."
              defaultValue={null}
              error={false}
            />
          </div>
        </div> */}
        <div className="col-span-2">
          <Controller
            name="pep_status"
            control={control1}
            rules={{
              required: true,
            }}
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                style={{ marginTop: "20px", textAlign: "left" }}
                control={
                  <Checkbox
                    style={{ marginRight: "30px" }}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                  />
                }
                label={
                  <Typography variant="body2">
                    Hiermit bestätige ich, dass ich selbst keine politisch
                    exponierte Person, kein Familienmitglied einer politisch
                    exponierten Person (PEP){" "}
                    <Tooltip
                      title={
                        <Typography variant="body2">
                          Eine politisch exponierte Person (PEP) ist eine
                          Person, die selbst ein hochrangiges wichtiges
                          öffentliches Amt (z.B. Politiker, Botschafter, Richter
                          oberster Gerichte) ausübt, ausgeübt hat oder eine
                          Person aus deren unmittelbaren persönlichen Umfeld
                          (z.B Familienmitglieder oder nahestehende Personen).{" "}
                        </Typography>
                      }
                      style={{
                        fontSize: "18px",
                        verticalAlign: "middle",
                      }}
                    >
                      <InfoRoundedIcon />
                    </Tooltip>{" "}
                    und keine einer politisch exponierten Person bekanntermaßen
                    nahestehende Person im Sinne der anwendbaren
                    Geldwäschegesetzgebung bin.
                  </Typography>
                }
              />
            )}
          />
        </div>
        <div className="col-span-2">
          <Controller
            name="is_beneficiary"
            control={control1}
            defaultValue={false}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                style={{ marginTop: "20px", textAlign: "left" }}
                control={
                  <Checkbox
                    style={{ marginRight: "30px" }}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value}
                  />
                }
                label={
                  <Typography variant="body2">
                    Ich versichere, auf eigene Rechnung und im eigenen
                    wirtschaftlichen Interesse zu handeln."
                  </Typography>
                }
              />
            )}
          />
        </div>
        {errors1.is_beneficiary || errors1.pep_status ? (
          <div className="col-span-2">
            <Typography variant="body2" className="text-red-600">
              Du kannst nur investieren, sofern du keine politisch exponierte
              Person bist und auf eigene Rechnung handelst. Bei Fragen
              kontaktiere bitte den Support unter invest@inventure.capital.
            </Typography>
          </div>
        ) : null}
        <div>
          <MultistepFormButtonsV2
            back={false}
            backFunction={function (): void {
              throw new Error("Function not implemented.");
            }}
            disabled={!formState1.isValid}
          />
        </div>
      </div>
    </form>
  );
}

function PersonalDataLegalPerson() {
  const investment_context = useContext(InvestmentContextFull);
  const app_context = useContext(AppContext);
  const [identityFile, setIdentityFile] = useState<File | null>();
  const [structureFile, setStructureFile] = useState<File | null>();

  const {
    register: register,
    control: control,
    formState: formState,
    formState: { errors: errors, isValid: isValid },
    handleSubmit: handleSubmit,
    setValue: setValue,
  } = useForm<any>({ mode: "onChange" });

  const person = app_context.user?.legal_person;

  interface LegalPersonData {
    company_name: string;
    legal_entity_type: LEGAL_ENTITY_TYPE;
    street: string;
    city: string;
    zip: string;
    country: COUNTRY;
    phone: string;
    commercial_register_number: string;
    commercial_register: string;
    company_identity_proof_id: string;
    company_structure_proof: string;
  }

  const onSubmit = (data: LegalPersonData) => {
    if (!structureFile || !identityFile) return;
    investment_context.setLoading(true);
    API_CLIENT.postLegalPerson(
      app_context.token,
      (user) => {
        API_CLIENT.postLegalPersonFiles(
          app_context.token,
          () => {
            app_context.updateUser(user);
          },
          structureFile,
          identityFile
        );
      },
      data as LegalPerson
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <div className="grid gap-16">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p className="text-xl font-medium">Unternehmensdaten</p>
            <p className="text-gray-600">
              Bitte trage die Unternehmensdaten Deiner Gesellschaft ein
            </p>
          </Grid>
          <Grid item xs={12} sm={8}>
            <HookFormTextFieldV2
              name="company_name"
              control={control}
              label="Unternehmensname*"
              defaultValue={person?.company_name}
              error={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <HookFormCompanyTypeSelectV2
              name="legal_entity_type"
              control={control}
              label="Unternehmensform*"
              defaultValue={person?.legal_entity_type}
              error={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <HookFormTextFieldV2
              name="commercial_register_number"
              control={control}
              label="Handelsregisternummer*"
              defaultValue={person?.commercial_register_number}
              error={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <HookFormTextFieldV2
              name="commercial_register"
              control={control}
              label="Handelsregister*"
              defaultValue={person?.commercial_register}
              error={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <HookFormTextFieldV2
              name="phone"
              control={control}
              label="Telefonnummer*"
              required
              defaultValue={person?.phone}
              error={false}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={6}>
            <HookFormTextFieldV2
              name="street"
              control={control}
              label="Straße und Haußnummer*"
              defaultValue={person?.street}
              error={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <HookFormTextFieldV2
              name="city"
              control={control}
              label="Stadt*"
              defaultValue={person?.city}
              error={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <HookFormTextFieldV2
              name="zip"
              control={control}
              label="Postleitzahl*"
              defaultValue={person?.zip}
              error={false}
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <HookFormCountrySelectV2
              name="country"
              control={control}
              label="Land*"
              defaultValue={person?.country}
              error={false}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">* Pflichtfeld</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p className="text-xl font-medium">Gesellschaftsunterlagen</p>
            <p className="text-gray-600">
              Bitte lade einen aktuellen Handelsregisterauszug (nicht älter als
              2 Monate), sowie die Liste der Gesellschafter hoch
            </p>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="w-full">
              <p className="text-sm">Handelsregisterauzug</p>
              <HookFormFileV2 onFileDrop={(file) => setIdentityFile(file)} />
            </div>
          </Grid>
          <Grid
            sx={{
              marginTop: {
                xs: "20px",
                sm: "0",
              },
            }}
            item
            xs={12}
            sm={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="w-full">
              <p className="text-sm">Liste der Gesellschafter</p>
              <HookFormFileV2 onFileDrop={(file) => setStructureFile(file)} />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MultistepFormButtonsV2
            back={false}
            backFunction={() => null}
            disabled={
              !formState.isValid ||
              structureFile == null ||
              identityFile == null
            }
          />
        </Grid>
      </div>
    </form>
  );
}

function PersonalDataBenefitingPerson() {
  return (
    <div className="grid gap-8">
      <div>
        <p className="text-sm text-gray-500 leading-3">Begünstigte Person</p>
        <p className="text-xl font-medium">
          Bitte gib hier die wirtschaftlich begünstigten Personen ein
        </p>
      </div>
      <BenefitingPersonManagerV2 />
    </div>
  );
}
